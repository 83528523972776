.text-input-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.text-input{
    border-radius: 5px;
    border: #282c34 solid 1px;
    height: 30px;
}

textarea.text-input{
    min-height: 100px;
    padding: 2px;
}