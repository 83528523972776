
body{
    margin: 0;
    padding: 0;
}

.container{
    /*margin-left: 3vw;
    margin-right: 3vw;*/
    margin: 3vw;
}

.row{
    height: 30vh;
    display: flex;

}

.row,
.left-description,
.left-image,
.right-image,
.right-description {
    /*border: black solid 1px;*/
}

.left-description,
.left-image,
.right-image,
.right-description {
    height: 100%;
}

.left-description,
.right-description {
    width: 30vw;
}

.left-image,
.right-image{
    width: 20vw;
}

.right-image{
    display: flex;
    justify-content: flex-start;
}

.left-image{
    display: flex;
    justify-content: flex-end;
}






.timeline-image{
    max-width: 20vw;
    object-fit: cover;
    height: 30vh;
    cursor: pointer;
}

.details-box-container,
.date-box{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.date-box-text{
    text-align: center;
}

.date-box-info{

}

.date-year{
    font-weight: bold;
    font-size: 4em;
}

.date-day-month{
    font-size: 1.3em;
}


.details-box-container{
    padding-right: 2vw;
    padding-left: 2vw;
}

/*.details-container{
    width: 50%;
}*/

.details-title{
    font-size: 2em;
    font-weight: 700;
    color: #156565;
    margin-bottom: 10px;
}

.details-title-left{
    text-align: right;
}

.details-title-right{
    text-align: left;
}

.details-box-left{
    justify-content: right;
}
.details-box-right{
    justify-content: left;
}

.details-box-left > div > div{
    text-align: right;
}

.details-box-right > div > div{
    text-align: left;
}


/*need to align the title/ description in middle on the image side of the row, so left side would be aligned right in the middle. bold title obv*/
