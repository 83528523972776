
button.danger-button{
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #dc3545;
    color: white;
    font-size: 1.3em;
    padding: 10px;
}
button:hover{
    cursor: pointer;
}
