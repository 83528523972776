.upload-page-container{
    display: flex;
    /*height: 100vh;*/
    height: auto;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.upload-form-container{
    min-width: 30vw;
}

.upload-form-container button{
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: #28a745;
    color: white;
    font-size: 1.3em;
    padding: 10px;
}
.upload-form-container button:hover{
    cursor: pointer;
}

.categories-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    /*justify-content: space-between;*/
    /*align-content: space-between;*/
}

.categories-container input{
    margin-left: 0;
}

.upload-page-container div img{
    width: 30vw;
}

@media only screen and (max-width: 900px) {
    .upload-page-container div img{
        width: 80vw;
    }
}
