.upload-area-container {
    position: relative;
    height: 30vh;
    width: 60vw;
    border: darkslategray dashed 3px;
    border-radius: 5px;
}
.upload-area-container input {
    height: 30vh;
    width: 60vw;
    opacity: 0;
}
.upload-area-container input:hover{
    cursor: pointer;
}
.upload-area-container span {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}